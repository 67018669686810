import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Floater from 'components/floating-grid'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/implements/utility-tractors/tillage-equipment/3-point-offset-disks/',
    imageId: 'offsetDisksImage',
    name: '3-Point Offset Disks',
    alt: '3-Point Offset Disks',
  },
  {
    link: '/implements/utility-tractors/tillage-equipment/cultipackers/',
    imageId: 'cultipackersImage',
    name: 'Cultipackers',
    alt: 'Cultipackers',
  },
  {
    link: '/implements/utility-tractors/tillage-equipment/cultivators/',
    imageId: 'cultivatorsImage',
    name: 'Cultivators',
    alt: 'Cultivators',
  },
  {
    link: '/implements/utility-tractors/tillage-equipment/disk-harrows/',
    imageId: 'diskHarrowsImage',
    name: 'Disk Harrows',
    alt: 'Disk Harrows',
  },
  {
    link: '/implements/utility-tractors/tillage-equipment/middle-busters/',
    imageId: 'middleBustersImage',
    name: 'Middle Busters',
    alt: 'Middle Busters',
  },
  {
    link: '/implements/utility-tractors/tillage-equipment/plows/',
    imageId: 'plowsImage',
    name: 'Plows',
    alt: 'Plows',
  },
  {
    link: '/implements/utility-tractors/tillage-equipment/rotary-tillers/',
    imageId: 'rotaryTillersImage',
    name: 'Rotary Tillers',
    alt: 'Rotary Tillers',
  },
  {
    link: '/implements/utility-tractors/tillage-equipment/shank-rippers/',
    imageId: 'shankRippersImage',
    name: 'Shank Rippers',
    alt: 'Shank Rippers',
  },
  {
    link: '/implements/utility-tractors/tillage-equipment/subsoilers/',
    imageId: 'subsoilersImage',
    name: 'Subsoilers',
    alt: 'Subsoilers',
  },
  {
    link: '/implements/utility-tractors/tillage-equipment/vertical-tillage-tools/',
    imageId: 'vtImage',
    name: 'Vertical Tillage Tools',
    alt: 'Vertical Tillage Tools',
  },
]

const TillageEquipmentPage = ({ data }) => {
  const { heroImage } = data
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>Utility Tractor Implements - Tillage Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='Break ground and turn up dirt with Frontier tillage equipment for John Deere utility tractors. Check out the full line-up at Hutson.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Implements',
                'item': 'https://www.hutsoninc.com/implements/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Utility Tractors',
                'item': 'https://www.hutsoninc.com/implements/utility-tractors/',
              },
              {
                '@type': 'ListItem',
                'position': 4,
                'name': 'Tillage Equipment',
                'item': 'https://www.hutsoninc.com/implements/utility-tractors/tillage-equipment/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Tillage Equipment' />

      <Content>
        <Floater items={gridItems} />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    heroImage: file(
      relativePath: { eq: "implements/utility-tractors/tillage-equipment-header.jpg" }
    ) {
      ...FullWidthImage
    }
    offsetDisksImage: file(
      relativePath: { eq: "implements/utility-tractors/tillage-equipment/3-point-offset-disks.jpg" }
    ) {
      ...FloatingGridImage
    }
    cultipackersImage: file(
      relativePath: { eq: "implements/utility-tractors/tillage-equipment/cultipackers.jpg" }
    ) {
      ...FloatingGridImage
    }
    cultivatorsImage: file(
      relativePath: { eq: "implements/utility-tractors/tillage-equipment/cultivators.jpg" }
    ) {
      ...FloatingGridImage
    }
    diskHarrowsImage: file(
      relativePath: { eq: "implements/utility-tractors/tillage-equipment/disk-harrows.jpg" }
    ) {
      ...FloatingGridImage
    }
    middleBustersImage: file(
      relativePath: { eq: "implements/utility-tractors/tillage-equipment/middle-busters.jpg" }
    ) {
      ...FloatingGridImage
    }
    plowsImage: file(
      relativePath: { eq: "implements/utility-tractors/tillage-equipment/plows.jpg" }
    ) {
      ...FloatingGridImage
    }
    rotaryTillersImage: file(
      relativePath: { eq: "implements/utility-tractors/tillage-equipment/rotary-tillers.jpg" }
    ) {
      ...FloatingGridImage
    }
    shankRippersImage: file(
      relativePath: { eq: "implements/utility-tractors/tillage-equipment/shank-rippers.jpg" }
    ) {
      ...FloatingGridImage
    }
    subsoilersImage: file(
      relativePath: { eq: "implements/utility-tractors/tillage-equipment/subsoilers.jpg" }
    ) {
      ...FloatingGridImage
    }
    vtImage: file(
      relativePath: {
        eq: "implements/utility-tractors/tillage-equipment/vertical-tillage-tools.jpg"
      }
    ) {
      ...FloatingGridImage
    }
  }
`

export default TillageEquipmentPage
